import React from 'react';
import './sidebar.styles.scss'
import resume from "./YuliiaKarakaiSoftwareEngineerResume.pdf";
import {Links} from "../links/links.component";

export const Sidebar = () => {
    return (
        <div className={'sidebar-block'}>
            <div className={"nav"}>
                <a className={"App-link"} href="#about">About me</a>
                <a className={"App-link"} href="#expertise">Expertise</a>
                <a className={"App-link"} href="#experience">Experience</a>
                <a className={"App-link"} href="#projects">Projects</a>
                <a className={"App-link"} href={resume} target={'_blank'}>Resume</a>
            </div>
            <div className={'links-section'}>
                <Links/>
            </div>
        </div>
    )
}