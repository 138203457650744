import React, { useState } from 'react';
import './experience.styles.scss'

export const Experience = () => {

    const experiences = [
        {
            company: 'Uberflip',
            position: 'Software Engineer, Infrastructure and DevOps',
            dates: '05/2023-07/2024',
            achievements: ["Built services through a combination of Terraform to provision infrastructure, Ansible and Go to configure servers, and GitHub to manage and deploy code.",
                "Assisted other developers with designing and implementing infra changes, deploying their code to production, migrating data and disaster recovery activities.",
                "Managed and updated Kubernetes clusters.",
                "Migrated a significant highly available system from an Amazon ECS + TeamCity setup to a more developer friendly setup using Amazon EKS with Github Actions.",
                "Retired legacy ElasticSearch servers and migrated data and functionality to Amazon OpenSearch.",
                "Part of an on-call rotation for critical production infrastructure."]
        },
        {
            company: 'Wattpad',
            position: 'Software Engineer, Platform',
            dates: '11/2021-03/2023',
            achievements: ['Participated in the decomposition of a large monolith PHP system into GO microservices.',
                'Collaborated with other engineers and end users to complete a large internal feature for the legal department.',
                'Completed a large project moving user comments functionality out of a PHP monolith into its own microservice.',
                'Set up new deployment pipelines and a staging environment for the new microservices.',
                'Built comprehensive test coverage for all new features and optimized existing tests through parallelization which brought down their overall run time by 46%.',
                'Aided in onboarding new engineers.']
        },
        {
            company: 'Start.ca',
            position: 'Software Developer',
            dates: '01/2020-11/2021',
            achievements: ['Created a new service using PHP and React for marketing to evaluate business performance.',
                'Collaborated with design and marketing specialists to perform a full invoice redesign to reflect modern design tendencies using the existing legacy PHP codebase.',
                'Participated in architecting, designing and building new E-commerce project using React.',
                'Performed migrations from legacy PHP and JavaScript code to new systems.']
        },
        {
            company: 'Info-Tech Research Group',
            position: 'Software Developer',
            dates: '04/2019-08/2019',
            achievements: ['Worked in a fast-paced agile environment to deliver new features and bug fixes.',
                'Supported, maintained, documented, and enhanced software functionality in Ruby.',
                'Completed assigned projects and tasks using standardized processes within timeline and requirements outlined by team leads.']
        },
        {
            company: 'NOVA Chemicals',
            position: 'Software Developer',
            dates: '09/2018-12/2018',
            achievements: ['Rebuilt three legacy VB6 applications into one .NET solution and delivered a complete solution before expected deadline.',
                'Migrated databases from Oracle to SQL Server.',
                'Documented new system and performed testing.']
        },
        {
            company: 'Sport-Travel',
            position: 'Software Developer',
            dates: '01/2018-04/2018',
            achievements: ['Collaborated with designers and Agile dev team to create high-quality interactive web experiences.',
            'Participated in rebuilding complex Single-Page application using Vue.js, Node.js and Google Firebase.']
        }
    ];

    const [experience, setExperience] = useState(experiences[0]);

    const [uberflipClicked, setUberflip] = useState(true);
    const [wattpadClicked, setWattpad] = useState(false);
    const [startClicked, setStart] = useState(false);
    const [novaClicked, setNova] = useState(false);
    const [infoTechClicked, setInfoTech] = useState(false);
    const [sportTravelClicked, setSportTravel] = useState(false);

    const onCompanyClicked = (e) => {
        if(e.id === 'uberflip') {
            setExperience(experiences.find(el => el.company === 'Uberflip'));

            //set clicked state for style
            setUberflip(true);

            //clear buttons
            setWattpad(false);
            setStart(false);
            setInfoTech(false);
            setNova(false);
            setSportTravel(false);
        } else if(e.id === 'wattpad') {
            setExperience(experiences.find(el => el.company === 'Wattpad'));

            //set clicked state for style
            setWattpad(true);

            //clear buttons
            setUberflip(false);
            setStart(false);
            setInfoTech(false);
            setNova(false);
            setSportTravel(false);
        } else if(e.id === 'start') {
            setExperience(experiences.find(el => el.company === 'Start.ca'));

            //set clicked state for style
            setStart(true);

            //clear buttons
            setUberflip(false);
            setWattpad(false);
            setInfoTech(false);
            setNova(false);
            setSportTravel(false);
        } else if(e.id === 'info-tech') {
            setExperience(experiences.find(el => el.company === 'Info-Tech Research Group'));

            //set clicked state for style
            setInfoTech(true);

            //clear buttons
            setUberflip(false);
            setWattpad(false);
            setStart(false);
            setNova(false);
            setSportTravel(false);
        } else if(e.id === 'nova') {
            setExperience(experiences.find(el => el.company === 'NOVA Chemicals'));

            //set clicked state for style
            setNova(true);

            //clear buttons
            setUberflip(false);
            setWattpad(false);
            setStart(false);
            setInfoTech(false);
            setSportTravel(false);
        } else if(e.id === 'sport-travel') {
            setExperience(experiences.find(el => el.company === 'Sport-Travel'));

            //set clicked state for style
            setSportTravel(true);

            //clear buttons
            setUberflip(false);
            setWattpad(false);
            setStart(false);
            setInfoTech(false);
            setNova(false);
        }
    }

    return (
        <div id={'experience'}>
            <h2>
                Experience
            </h2>
            <div className={'experience-block'}>
                <div className={'companies-buttons'}>
                    <a className={uberflipClicked ? 'button-clicked' : ''} onClick={e => onCompanyClicked(e.target)} id={'uberflip'}>Uberflip</a>
                    <a className={wattpadClicked ? 'button-clicked' : ''} onClick={e => onCompanyClicked(e.target)} id={'wattpad'}>Wattpad</a>
                    <a className={startClicked ? 'button-clicked' : ''} onClick={e => onCompanyClicked(e.target)} id={'start'}>Start.ca</a>
                    <a className={infoTechClicked ? 'button-clicked' : ''} onClick={e => onCompanyClicked(e.target)} id={'info-tech'}>Info-Tech Research Group</a>
                    <a className={novaClicked ? 'button-clicked' : ''} onClick={e => onCompanyClicked(e.target)} id={'nova'}>NOVA Chemicals</a>
                    <a className={sportTravelClicked ? 'button-clicked' : ''} onClick={e => onCompanyClicked(e.target)} id={'sport-travel'}>Sport-Travel</a>
                </div>
                <div className={uberflipClicked || wattpadClicked || startClicked || infoTechClicked || novaClicked || sportTravelClicked ?'details fade' : 'details'}>
                {/*<div className={'details'}>*/}
                    <h3>{experience.company}</h3>
                    <h4>{experience.position}</h4>
                    <h6>{experience.dates}</h6>
                    <ul>{experience.achievements.map((value, index) => {
                        return <li>{value}</li>
                    })}
                    </ul>
                </div>
            </div>
        </div>
    )
}
