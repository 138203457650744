import React from 'react';
import './about.styles.scss'

export const About = () => {
    return (
        <div className={"about-block"} id={"about"}>
            <h1>
                <span>Hello!</span> I am Yuliia
            </h1>
            <p>
                I am a Software Engineer based out of London, Ontario.
                Skilled at writing clear, concise, testable code that is easy to understand, maintain and debug.
                Experienced  with a variety of programming languages and tools.
            </p>
        </div>
    )
}