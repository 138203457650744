import React from 'react';
import './links.styles.scss'

export const Links = () => {
    return (
        <div className={"links"}>
            <i className="fab fa-bitbucket fa-inverse" onClick={() => {
                window.open(
                    'https://bitbucket.org/karakaiyuliia',
                    '_blank'
                );
            }}></i>
            <i className="fa-brands fa-linkedin-in fa-inverse" onClick={() => {
                window.open(
                    'https://www.linkedin.com/in/yuliiakarakai/',
                    '_blank'
                );
            }}></i>
        </div>
    );
}