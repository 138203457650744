import React from 'react';
import './expertise.styles.scss'

export const Expertise = () => {
    return (
        <div className={"expertise-block"} id={"expertise"}>
            <h2>
                My expertise
            </h2>
            <div className={"expertise-list"}>
                <div>
                    <i className="fa-brands fa-php fa-3x"></i>
                    <p>PHP</p>
                </div>
                <div>
                    <i className="fa-brands fa-golang fa-3x"></i>
                    <p>Go</p>
                </div>
                <div>
                    <i className="fa-brands fa-java fa-3x"></i>
                    <p>Java</p>
                </div>
                <div>
                    <i className="fa-brands fa-react fa-3x"></i>
                    <p>React</p>
                </div>
                <div>
                    <i className="fa-brands fa-node fa-3x"></i>
                    <p>Node.js</p>
                </div>
                <div>
                    <i className="fa-brands fa-docker fa-3x"></i>
                    <p>Docker</p>
                </div>
                <div>
                    <i className="fa-solid fa-server fa-3x"></i>
                    <p>Kubernetes</p>
                </div>
                <div>
                    <i className="fa-solid fa-database fa-3x"></i>
                    <p>SQL/NoSQL</p>
                </div>
                <div>
                    <i className="fa-brands fa-aws fa-3x"></i>
                    <p>AWS</p>
                </div>
                <div>
                    <i className="fa-solid fa-code fa-3x"></i>
                    <p>Terraform</p>
                </div>
            </div>
        </div>

    )
}
