import './App.scss';
import {Sidebar} from "./components/sidebar/sidebar.component";
import {About} from "./components/about/about.component";
import {Expertise} from "./components/expertise/expertise.component";
import {Experience} from "./components/experience/experience.component";
import {Projects} from "./components/projects/projects.component";
import {Links} from "./components/links/links.component";

function App() {
  return (
    <div className="App">
        <div className={'App-sidebar'}>
            <Sidebar/>
        </div>
        <div className={'App-content'}>
            <About/>
            <Expertise/>
            <Experience/>
            <Projects/>
        </div>
        <div/>
        <div style={{position: "relative", width: '100%'}}>
            <div style={{position: "absolute", width: '100%', fontSize: '1rem'}}>
                <div className={'App-links'}>
                    <Links/>
                </div>
                <p>Designed and built by Yuliia Karakai {new Date().getFullYear()}</p>
            </div>
        </div>

    </div>
  );
}

export default App;
