import React from 'react';
import './projects.styles.scss'

export const Projects = () => {
    const projects = [
        {
            name: 'Solute Art Studio',
            description: 'Website for a small art studio in London, ON. It\'s built using, React, Next.js and Tailwind CSS on the frontend, Go on the backend and deployed to DigitalOcean\'s App Platform using Docker images built and pushed by Github Actions.',
            icon: 'fa-light fa-laptop-mobile fa-4x',
            stack: ['fa-brands fa-react', 'fa-brands fa-golang', 'fa-solid fa-database', "fa-brands fa-docker", "fa-brands fa-digital-ocean", "fa-brands fa-github"],
            website: 'https://soluteartstudio.com/'
        },
        {
            name: 'Bookings',
            description: 'Hotel booking system written in Go. Originally created to practice Go.',
            icon: 'fa-light fa-laptop fa-4x',
            stack: ['fa-brands fa-golang', 'fa-solid fa-database'],
            bitbucket: 'https://bitbucket.org/karakaiyuliia/bookings'
        },
        {
            name: 'Doggo recognition',
            description: 'Inspired by Silicon Valley, an app that uses Apple\'s ML Kit to differentiate between dogs and not dogs.',
            icon: 'fa-light fa-mobile-notch fa-4x',
            stack: ['fa-brands fa-swift', 'fa-solid fa-microchip-ai'],
            bitbucket: 'https://bitbucket.org/karakaiyuliia/doggorecognition'
        }
    ]
    return (
        <div className={'project-block'} id={'projects'}>
            <h2>
                What I've been up to
            </h2>
            <div className={'project-list'}>
                {projects.map((value, index) => {
                    return <div className={'project-card'}>
                        <div className={'project-icon'}>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <i className={`${value.icon} icon`}></i>
                            </div>
                        </div>
                        <div className={'project-description'}>
                            <div className={'project-name'}>
                                <h4>{value.name}</h4>
                                {value.bitbucket && <i className="fa-solid fa-code-branch bitbucket-link" onClick={() => {
                                    window.open(
                                        `${value.bitbucket}`,
                                        '_blank'
                                    );
                                }}></i>}
                                {value.website && <i className="fa-sharp fa-regular fa-globe-pointer bitbucket-link" onClick={() => {
                                    window.open(
                                        `${value.website}`,
                                        '_blank'
                                    );
                                }}></i>}
                            </div>
                            <p>{value.description}</p>
                            <div>
                                {value.stack.map((s, i) => {
                                    return <i className={`${s} stack`} style={{marginLeft: i > 0 ? '1rem': ''}}/>
                                })
                                }
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}
